import React, { useState, createContext, useEffect, useContext } from 'react'
import { fallbackCurrency, supportedCurrencies } from '@services/pricing'
import { getGeo } from '@services/client/api'

export type GeoState = GeoStateLoading | GeoStateReady

export interface GeoStateLoading {
    initialized: false
}

export interface GeoStateReady {
    initialized: true
    countryCode: string
    currency: string
    timezone: string
}

const geoContext = createContext<{ state: GeoState }>(null)

export const GeoProvider: React.FC<React.PropsWithChildren> = props => {
    const [state, setState] = useState<GeoState>({
        initialized: false,
    })

    const fetchGeo = async () => {
        const result = await getGeo()
        if (!result.error) {
            setState({
                initialized: true,
                countryCode: result.countryCode,
                currency: supportedCurrencies.includes(result.currency) ? result.currency : fallbackCurrency,
                timezone: result.timezone,
            })
        } else {
            // fallback
            setState({
                initialized: true,
                countryCode: 'US',
                currency: fallbackCurrency,
                timezone: 'America/New_York',
            })
        }
    }

    useEffect(() => {
        fetchGeo()
    }, [])

    return <geoContext.Provider value={{ state }}>{props.children}</geoContext.Provider>
}

export const useGeo = (): GeoState => {
    const { state } = useContext(geoContext)
    return state
}

export const withGeo = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
    return (props: P) => {
        return (
            <GeoProvider>
                <WrappedComponent {...props} />
            </GeoProvider>
        )
    }
}
