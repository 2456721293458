import { ApiError } from './api'
import * as Sentry from '@sentry/nextjs'
import { fbAuth } from './firebaseClient'
import { getIdToken } from 'firebase/auth'
import { SubscriptionState as ServerSubscriptionState } from '@services/server/subscription'

export const getSubscription = async (): Promise<{
    data?: ServerSubscriptionState
    error?: ApiError
}> => {
    try {
        const idToken = await getIdToken(fbAuth.currentUser)
        const resp: {
            data?: ServerSubscriptionState
            error?: ApiError
        } = await fetch('/api/subscription', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + idToken,
            },
        })
            .then(r => r.text())
            .then(body => {
                try {
                    return JSON.parse(body)
                } catch (e) {
                    return { error: { type: 'api_error', code: 'unknown', message: body } }
                }
            })
        if (resp.error) {
            Sentry.captureException(`API getSubscription error: ${JSON.stringify(resp.error)}`)
            return { error: resp.error }
        }
        return { data: resp.data }
    } catch (e) {
        Sentry.captureException(e)
        return {
            error: {
                type: 'api_error',
                code: 'unknown',
                message: e as string,
            },
        }
    }
}

export const cancelSubscription = async (params: { store: string; subscriptionID: string }) => {
    try {
        const idToken = await getIdToken(fbAuth.currentUser)
        const resp: { data?: any; error?: ApiError } = await fetch('/api/subscription', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + idToken,
            },
            body: JSON.stringify({
                store: params.store,
                subscriptionID: params.subscriptionID,
            }),
        })
            .then(r => r.text())
            .then(body => {
                try {
                    return JSON.parse(body)
                } catch (e) {
                    return { error: { type: 'api_error', code: 'unknown', message: body } }
                }
            })
        if (resp.error) {
            Sentry.captureException(`Subscription cancelSubscription Error: ${JSON.stringify(resp.error)}`)
            return { error: resp.error }
        }
        return {}
    } catch (e: unknown) {
        Sentry.captureException(e)
        return {
            error: {
                type: 'api_error',
                code: 'unknown',
                message: e as string,
            },
        }
    }
}

export const updateSubscription = async (params: {
    store: string
    subscriptionID: string
    currency?: string
    price?: string
    priceID?: string
}): Promise<{ error?: ApiError }> => {
    try {
        const idToken = await getIdToken(fbAuth.currentUser)
        const resp: { data?: any; error?: ApiError } = await fetch('/api/subscription', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + idToken,
            },
            body: JSON.stringify({
                store: params.store,
                subscriptionID: params.subscriptionID,
                currency: params.currency,
                price: params.price,
                priceID: params.priceID,
            }),
        })
            .then(r => r.text())
            .then(body => {
                try {
                    return JSON.parse(body)
                } catch (e) {
                    return { error: { type: 'api_error', code: 'unknown', message: body } }
                }
            })
        if (resp.error) {
            Sentry.captureException(`Subscription updateSubscription Error: ${JSON.stringify(resp.error)}`)
            return { error: resp.error }
        }
        return {}
    } catch (e: unknown) {
        Sentry.captureException(e)
        return {
            error: {
                type: 'api_error',
                code: 'unknown',
                message: e as string,
            },
        }
    }
}

export const pauseSubscription = async (params: { store: string; subscriptionID: string; expiresDate: string }) => {
    const { store, subscriptionID, expiresDate } = params
    try {
        const idToken = await getIdToken(fbAuth.currentUser)
        const resp: any = await fetch('/api/subscription', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + idToken,
            },
            body: JSON.stringify({
                store: store,
                subscriptionID: subscriptionID,
                expiresDate: expiresDate,
                action: 'PAUSE',
            }),
        })
        if (resp.error) {
            Sentry.captureException(`Subscription pause subscription Error: ${JSON.stringify(resp.error)}`)
            return { error: resp.error }
        }
        return resp
    } catch (e: unknown) {
        Sentry.captureException(e)
        return {
            error: {
                type: 'api_error',
                code: 'unknown',
                message: e as string,
            },
        }
    }
}
