import React, { useState, createContext, useEffect, useContext } from 'react'
import { useAuth } from '@contexts/auth'
import { getSubscription } from '@services/client/subscription'

export type SubscriptionState = SubscriptionStateLoading | SubscriptionStateNotPurchased | SubscriptionStatePurchased

export interface SubscriptionStateLoading {
    initialized: false
}

export interface SubscriptionStateNotPurchased {
    initialized: true
    status: 'not_purchased'
}

export interface SubscriptionStatePurchased {
    initialized: true
    status: 'active' | 'expired'
    store: string
    originalPurchaseDate: Date
    latestPurchaseDate: Date
    expirationDate: Date
    willRenew: boolean
    unsubscribeDetectedAt?: Date
    billingIssuesDetectedAt?: Date
}

const subContext = createContext<{ state: SubscriptionState }>(null)

export const SubscriptionProvider: React.FC<React.PropsWithChildren> = props => {
    const [user, authInitialized] = useAuth()

    const [state, setState] = useState<SubscriptionState>({
        initialized: false,
    })

    const fetchSub = async () => {
        const res = await getSubscription()
        if (res.data && res.data.status !== 'not_purchased') {
            setState({
                initialized: true,
                status: res.data.status,
                store: res.data.store,
                originalPurchaseDate: new Date(res.data.originalPurchaseDate),
                latestPurchaseDate: new Date(res.data.latestPurchaseDate),
                expirationDate: new Date(res.data.expirationDate),
                willRenew: res.data.willRenew,
                unsubscribeDetectedAt: res.data.unsubscribeDetectedAt && new Date(res.data.unsubscribeDetectedAt),
                billingIssuesDetectedAt: res.data.billingIssuesDetectedAt && new Date(res.data.billingIssuesDetectedAt),
            })
        } else {
            setState({ initialized: true, status: 'not_purchased' })
        }
    }

    useEffect(() => {
        if (authInitialized && user?.id) {
            fetchSub()
        }
    }, [authInitialized, user?.id])

    return <subContext.Provider value={{ state }}>{props.children}</subContext.Provider>
}

export const useSubscription = (): SubscriptionState => {
    const { state } = useContext(subContext)
    return state
}

export const withSubscription = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
    return (props: P) => {
        return (
            <SubscriptionProvider>
                <WrappedComponent {...props} />
            </SubscriptionProvider>
        )
    }
}
