import React, { useState, createContext, useContext } from 'react'
import { Pricing, Store } from '@services/pricing'

export type PaymentState = PaymentStateNotPurchased | PaymentStatePurchased

export interface PaymentStateNotPurchased {
    purchased: false
}

export interface PaymentStatePurchased {
    purchased: true
    provider: Store
    price: Pricing
    pricing_variant: string
}

interface PaymentContext {
    state: PaymentState
    setPurchase: (provider: Store, price: Pricing, pricing_variant: string) => void
}

const paymentContext = createContext<PaymentContext>(null)

export const PaymentProvider: React.FC<React.PropsWithChildren> = props => {
    const [state, setState] = useState<PaymentState>({
        purchased: false,
    })

    const setPurchase = (provider: Store, price: Pricing, pricing_variant: string) => {
        setState({
            purchased: true,
            provider,
            price,
            pricing_variant,
        })
    }

    return <paymentContext.Provider value={{ state, setPurchase }}>{props.children}</paymentContext.Provider>
}

export const usePayment = (): PaymentContext => {
    return useContext(paymentContext)
}

export const withPayment = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
    return (props: P) => {
        return (
            <PaymentProvider>
                <WrappedComponent {...props} />
            </PaymentProvider>
        )
    }
}
