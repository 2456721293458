import classNames from 'classnames'
import React from 'react'
import { LoadingSpinner } from './LoadingSpinner'

export interface ButtonProps {
    primary?: boolean
    label: string | React.ReactNode
    disabled?: boolean
    className?: string
    headerButton?: boolean
    processing?: boolean
    fullWidth?: boolean
    type?: 'button' | 'submit' | 'reset'
    customBg?: { default: string; hover: string }
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

/**
 * Primary UI component for user interaction
 */
export const Button: React.FC<ButtonProps> = ({
    primary = false,
    label,
    disabled,
    className,
    headerButton = false,
    processing = false,
    fullWidth = false,
    type = 'button',
    onClick,
    customBg,
    ...props
}) => {
    const defaultBg = { default: 'bg-green-500', hover: 'hover:bg-green-400' }
    const secondaryBg = { default: 'bg-light3', hover: 'hover:bg-light2' }
    const buttonStyles = classNames(
        'flex items-center justify-center font-extrabold text-center rounded-lg text-sm md:text-base 3xl:text-lg duration-150 ease-in-out outline-none focus:outline-none',
        primary &&
            (disabled
                ? 'bg-dark5 text-dark4 cursor-not-allowed'
                : `${customBg?.default || defaultBg.default} ${customBg?.hover || defaultBg.hover} text-white`),
        !primary &&
            (disabled ? '' : `${customBg?.default || secondaryBg.default} ${customBg?.hover || secondaryBg.hover}`),
        headerButton ? 'h-8 py-2 px-4' : 'py-4 px-6',
        fullWidth && 'w-full',
        className,
    )

    const loadingSpinnerStyle = classNames(primary ? 'text-white' : 'text-gray-500')

    return (
        <button onClick={onClick} type={type} className={buttonStyles} disabled={disabled} {...props}>
            {processing ? <LoadingSpinner size="sm" className={loadingSpinnerStyle} /> : label}
        </button>
    )
}
